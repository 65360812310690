
import { Component, Prop, Vue } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import ReportQuery from '@/models/ReportQuery'

import report_options_and_settings from '@/pages/Report/report-options'
import WebMessage from '@/models/WebMessage'
import { groupBy, startCase, uniqBy } from 'lodash'
import moment from 'moment'

@Component({
  components: {
    SelectPicker,
    DatePicker,
    IconAction,
    FormInput,
    DataTable,
  },
})
export default class ModularReport extends ViewModel {
  @Prop()
  public pre_select!: any

  @Prop()
  public custom_filters!: any

  @Prop()
  public no_data_message!: any

  public report: ReportQuery = new ReportQuery()

  public settings_list: any = {}

  public data: any = []

  public fields: object[] = []

  public sortBy: string = 'date'

  public sortDesc: boolean = true

  public loading: boolean = true

  public busy: boolean = true

  public show_report: boolean = false

  public get internal_settings() {
    return {
      type: this.pre_select ?? 'delivery',
      filters: this.custom_filters ?? {},
    }
  }

  public mounted() {
    this.report.formType = this.pre_select ?? 'delivery'
    this.setPreselected(this.report.formType)

    setTimeout(() => {
      if (this.custom_filters) {
        let obj = Object.assign(this.report.settings.filters, this.custom_filters)

        Vue.set(this.report.settings, 'filters', obj)
      }
      this.loading = false
      this.busy = false
    }, 300)
  }

  public setPreselected(val: string) {
    let found: any = report_options_and_settings.find(g => g.content.value === val)

    this.report.updateSettings({
      metrics: found.content.metrics.filter(d => d.selected).map(item => item.value),
      dimensions: found.content.dimensions.filter(d => d.selected).map(item => item.value),
    })

    Vue.set(this, 'settings_list', found)
  }

  public download() {
    this.loading = true
    WebMessage.success('Your report is being generated. Please wait for the download to start.')
    this.report.download().then(() => {
      this.loading = false
    })
  }

  public canRun(): boolean {
    if (this.report.type === 'delivery') {
      if (
        this.report.settings.filters.dmas.length > 0
        && (this.report.settings.metrics.length > 1
          || this.report.settings.metrics[0] != 'impressions')
      ) {
        WebMessage.error('DMA Filter is only compatible with the "impressions" metric', [
          {
            text: 'Fix Filters',
            action: (toast: any) => {
              this.report.settings.filters.dmas = []
              WebMessage.hide(toast.id)
              this.run()
            },
          },
          {
            text: 'Fix Metrics',
            action: (toast: any) => {
              this.report.settings.metrics = ['impressions']
              WebMessage.hide(toast.id)
              this.run()
            },
          },
        ])
      }
    }

    return true
  }

  public run() {
    if (!this.canRun()) {
      return
    }

    if (this.settings_list.content && this.settings_list.content.export_only) {
      this.download()
      return
    }
    this.loading = true
    this.report
      .fetch()
      .then(response => {
        if (typeof response.data.result == 'string') {
          this.loading = false
          WebMessage.warning(
            'Your report is too large to display. A download should start shortly.',
          )
          return
        }
        this.data = []
        this.data = response.data.result

        this.fields = []

        for (const key in response.data.result[0]) {
          if (key !== 'advertiser_id') {
            this.fields.push({
              key,
              sortable: true,
              class: 'text-center',
            })
          }
        }
        this.show_report = true
        this.loading = false
      })
      .catch(error => {
        this.loading = false
        if (error.response.status == 422) {
          let message = ''
          let type = ''
          if (error.response.data.errors.dimensions) {
            message = error.response.data.errors.dimensions[0]
            type = 'dimenssions'
          } else {
            message = error.response.data.errors.metrics[0]
            type = 'frequency'
          }
          WebMessage.error(message, [
            {
              text: 'Fix Dimensions',
              action: (toast: any) => {
                const dimensions: any = []
                this.report.settings.dimensions.forEach(item => {
                  if (type == 'frequency') {
                    if (
                      !['date', 'hour', 'device_type', 'region', 'order', 'line_item'].includes(
                        item,
                      )
                    ) {
                      dimensions.push(item)
                    }
                  } else if (item != 'hour' && item != 'region') {
                    dimensions.push(item)
                  }
                })

                if (type == 'frequency') {
                  if (!dimensions.includes('media_plan')) {
                    dimensions.push('media_plan')
                  }

                  if (!dimensions.includes('media_plan_item')) {
                    dimensions.push('media_plan_item')
                  }
                }

                this.report.settings.dimensions = dimensions
                WebMessage.hide(toast.id)
                this.run()
              },
            },
            {
              text: 'Fix Metrics',
              action: (toast: any) => {
                const metrics: any = []
                this.report.settings.metrics.forEach(item => {
                  if (type == 'frequency') {
                    if (item != 'lifetime_frequency') {
                      metrics.push(item)
                    }
                  } else if (item == 'impressions') {
                    metrics.push(item)
                  }
                })

                this.report.settings.metrics = metrics
                WebMessage.hide(toast.id)
                this.run()
              },
            },
          ])
        }
      })
  }

  public normalizeCode(code: string) {
    return startCase(code.replace(/_/g, ' '))
  }

  public getTotal(key: string) {
    let total = 0
    let count = 0

    const special_processing = ['completion_rate', 'lifetime_frequency', 'daily_avg_frequency']

    for (const i in this.data) {
      let val = 0
      if (special_processing.includes(key)) {
        // @ts-ignore
        val = parseFloat(this.data![i][key] ?? 0)
        total += val
      } else {
        // @ts-ignore
        val = parseInt(this.data![i][key] ?? 0)
        total += val
      }
      if ((key != 'lifetime_frequency' && key != 'daily_avg_frequency') || val > 0) count++
    }

    if (special_processing.includes(key)) {
      total /= count
    }
    return total
  }
}
