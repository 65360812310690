
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import state_list from '@/data/state_list_option' // contains all countries states
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'

@Component({
  components: {
    FormInput,
    IconAction,
  },
})
export default class StateTargetting extends ViewModel {
  @Prop()
  value!: any

  @Prop()
  exclude!: any

  @Prop()
  countries!: any

  public selected_nodes: Array<any> = []

  public filter: string = ''

  public get list_of_states() {
    if (!this.countries || !this.countries.length) return []
    let filtered = state_list.filter((state: any) => this.countries.includes(state.value))

    if (this.filter.length) {
      filtered = filtered.filter(state => this.filterStates(state))
    }

    return filtered.sort((a, b) => a.name.localeCompare(b.name))
  }

  public filterStates(state: any) {
    let values: any = state.name.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase())
      || state.isoCode.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase())
    return values
  }

  public isSelected(node: any) {
    return this.selected_nodes.some(sn => sn.value === node.value)
  }

  public select(node: any, include: boolean = true) {
    let index = this.selected_nodes.findIndex((s: any) => s.isoCode === node.isoCode)
    let _action = include ? 'include' : 'exclude'
    let obj = {
      ...node,
      action: _action,
    }

    if (index > -1) {
      Vue.set(this.selected_nodes[index], 'action', _action)
    } else {
      this.selected_nodes.push(obj)
    }

    this.emit()
  }

  public remove(index: number) {
    this.selected_nodes.splice(index, 1)
  }

  public removeAll() {
    this.selected_nodes = []
    this.emit()
  }

  public emit() {
    setTimeout(() => {
      let include = this.selected_nodes.filter(sn => sn.action === 'include')
      let exclude = this.selected_nodes.filter(sn => sn.action === 'exclude')
      const map_values = (arr: any) =>
        arr.map((n: any) => n.isoCode) // Iso Code is the code of its states

      this.$emit('input', map_values(include))
      this.$emit('update:exclude', map_values(exclude))
    }, 300)
  }
}
