import Model from './interface/Model'

export default class RemoteTarget extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'remote-target' as string | null,
      plural: 'remote-targets' as string | null,
    },
  }

  public id: any = null

  public name: any = null

  public remote_connection: any = null

  public type: any = null

  public inventory_id: any = null

  public parent_id: any = null

  public order_id: any = null

  public line_item_id: any = null

  public remote_creative_id: any = null

  public current_goal: any = null

  public delivered_goal: any = null

  public target_goal: any = null

  public status: any = null

  public disabled: any = null

  public archived_at: any = null
}
