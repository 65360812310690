
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import ViewModel from '@/models/ViewModel'
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import LineItem from '@/models/LineItem'
import IconAction from '@/components/IconAction/IconAction.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { percentageMask, currencyMask } from '@/models/interface/Masks'
import moment from 'moment'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import WebMessage from '@/models/WebMessage'
import { capitalize, clone, now } from 'lodash'
import LabelPicker from '@/components/LabelPicker/LabelPicker.vue'
import Order from '@/models/Order'
import OrderPicker from '@/components/OrderPicker/OrderPicker.vue'
import dmas from '@/data/dmas'
import state_options from '@/data/state_options'
import EventTracker from '@/components/EventTracker/EventTracker.vue'
import MediaPlanItem from '@/models/MediaPlanItem'
import MediaPackage from '@/models/MediaPackage'
import AssociatedCreative from '@/models/AssociatedCreative'
import ModularReport from '@/components/ModularReport/ModularReport.vue'
import Creative from '@/models/Creative'
import RemoteTargetTable from '@/components/RemoteTargetTable/RemoteTargetTable.vue'
import numeral from 'numeral'
import DeviceCategory from './components/Targetting/DeviceCategory.vue'
import CustomTargetting from './components/Targetting/CustomTargetting.vue'
import CountryTargetting from './components/Targetting/CountryTargetting.vue'
import StateTargetting from './components/Targetting/StateTargetting.vue'
import CityTargetting from './components/Targetting/CityTargetting.vue'
import GeoTargetting from './components/Targetting/GeoTargetting.vue'
import InventoryTargetting from './components/Targetting/InventoryTargetting.vue'
import LineItemCreative from './components/LineItemCreative.vue'
import CreativeForm from '../Creative/components/CreativeForm.vue'
import TimeRestriction from './components/TimeRestriction.vue'
import LineItemForm from './components/LineItemForm.vue'

// Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    CompanyPicker,
    FormInput,
    Widget,
    FormWizard,
    TabContent,
    UserPicker,
    SelectPicker,
    IconAction,
    FooterNav,
    DatePicker,
    PageHeader,
    LabelPicker,
    OrderPicker,
    DeviceCategory,
    CustomTargetting,
    EventTracker,
    CountryTargetting,
    StateTargetting,
    CityTargetting,
    GeoTargetting,
    InventoryTargetting,
    LineItemCreative,
    CreativeForm,
    TimeRestriction,
    LineItemForm,
    ModularReport,
    RemoteTargetTable,
  },
})
export default class LineItemEdit extends ViewModel {
  @Ref() creative_form!: CreativeForm

  @Ref() country_target!: any

  @Ref() line_item_form!: LineItemForm

  @Ref() line_item_creative!: any

  @Ref() readonly formWizard!: any

  @Ref() readonly includeTree: any

  @Ref() readonly excludeTree: any

  @Ref() readonly timeRestrictionRef: any

  @Ref() validator!: any

  public create_new_creative: boolean = false

  public edit_creative: boolean = false

  public loading_creative: boolean = false

  public wait: boolean = false

  public modal_open: boolean = false

  public hide_footer: boolean = false

  public expanded: boolean = false

  public busy: boolean = true

  // @Watch('busy')
  // public onBusyChange(val: any) {
  //   console.log('the busy changed state', val)
  // }

  public step: number = 1

  public _isEditing: boolean = false

  public order: Order = new Order()

  public order_line_item: LineItem = new LineItem()

  public show_creatives: boolean = true

  public show_metrics = true

  public target_media_plan_item: MediaPlanItem = new MediaPlanItem()

  public get hasUnsavedUpdated() {
    return this.order_line_item.is_dirty
  }

  public checkDirtyRecords(
    callback: any,
    message: string = 'You have unsaved changes, if you proceed you might lose your changes. Do you want to save it first?',
  ) {
    // check if dirty
    if (this.hasUnsavedUpdated) {
      return WebMessage.confirm(message, 'Unsaved Items', {
        okTitle: 'Save',
        cancelTitle: 'Discard',
      }).then((save: boolean) => {
        if (save) {
          this.save(false)
        }
        return callback ? callback() : Promise.resolve()
      })
    }
    return callback ? callback() : Promise.resolve()
  }

  // public beforeRouteLeave(to: object, from: object, next: any) {
  //   if (!this.hasUnsavedUpdated) {
  //     next()
  //   } else {
  //     this.checkDirtyRecords(() => {
  //       next()
  //     })
  //   }
  // }

  public get options() {
    return {
      tree: [
        {
          text: 'DMAs',
          id: null,
          state: { selectable: false },
          children: dmas
            .map(d => ({
              text: `${d.name} (${d.id})`,
              type: 'dma',
              id: d.id,
              state: { selectable: true },
            }))
            .sort((a, b) => a.text.localeCompare(b.text)),
        },
        {
          text: 'States',
          id: null,
          state: { selectable: false },
          children: state_options
            .map(d => ({
              text: `${d.name} (${d.value})`,
              type: 'state',
              id: d.value,
              state: { selectable: true },
            }))
            .sort((a, b) => a.text.localeCompare(b.text)),
        },
      ],
    }
  }

  public get reportSettings() {
    if (this.order_line_item && this.order_line_item.id && this.order_line_item.id.length) {
      return {
        adserver_line_items: [this.order_line_item.id],
      }
    }

    return {}
  }

  public savedCreative(creative: any) {
    setTimeout(() => {
      this.line_item_creative.$refs.creative_table.temp_creative.creative = creative.creative

      if (this.edit_creative) {
        let creative_index = this.order_line_item.associated_creatives.findIndex(
          (c: any) => c.creative_id === creative.creative.id,
        )

        if (creative_index > -1) {
          // this.order_line_item.associated_creatives[creative_index].creative = creative.creative
          this.order_line_item.associated_creatives[creative_index].creative = Creative.toObject(
            creative.creative,
          )
        }
        this.edit_creative = false
      } else {
        this.line_item_creative.$refs.creative_table.local_creatives.push(
          AssociatedCreative.toObject(
            clone(this.line_item_creative.$refs.creative_table.temp_creative),
          ),
        )
      }
    }, 100)
  }

  public get Moment() {
    moment.updateLocale('en', {
      week: {
        dow: 0, // Sunday is the first day of the week.
      },
    })
    return moment
  }

  public get masks() {
    return { percentageMask, currencyMask }
  }

  public mounted() {
    this.loadLocalData()
  }

  public loadLocalData() {
    let promises = []
    if (this.$route.query.order_id) {
      promises.push(this.setDataFromOrder({ value: this.$route.query.order_id }))
    }
    if (this.$route.query.media_plan_id && this.$route.query.media_plan_id.length) {
      promises.push(this.setDataFromMediaPlanItem())
    } else if (this.order_line_item.media_plan_item_id) {
      promises.push(
        MediaPlanItem.find(this.order_line_item.media_plan_item_id).then(
          (media_plan_item: MediaPlanItem) => {
            this.target_media_plan_item = media_plan_item
          },
        ),
      )
    }

    Promise.all(promises)
      .then(() => {
        this.busy = false
      })
      .catch(() => {
        this.busy = false
      })
  }

  public save(close: boolean) {
    this.checkForMissingConfig(() => {
      this.order_line_item.save().then((res: any) => {
        if (res?.status === 200 || res?.status === 201) {
          if (close) {
            this.order_line_item = LineItem.toObject(res.data.result.line_item)
            this.cancel()
          } else {
            this.show_creatives = false
            this.order_line_item = LineItem.toObject(res.data.result.line_item)
            setTimeout(() => {
              this.show_creatives = true
              if (!this.$route.params.id || this.$route.query.cloning) {
                this.$router.push({
                  name: 'line-item',
                  params: { id: res.data.result.line_item.id },
                })
              }

              this.line_item_form.reload()
            }, 100)
          }
        }
        this.hide_footer = false
        this.loading = false
      })
    })
  }

  public checkBookedImpressions(callBack: any) {
    if (
      !this.order_line_item.media_plan_item.id
      || !this.order_line_item.media_plan_item.id.length
    ) {
      callBack()
      return
    }
    // if:
    //   Has Media Plan Item associated
    //   Media Plan Item is NOT Open ended
    //   Media Plan Item impressions is greater than 0
    //   ----
    if (
      this.order_line_item.media_plan_item_id
      && !this.order_line_item.media_plan_item.open_ended
      && this.order_line_item.media_plan_item.impressions > 0
    ) {
      // Then, If:
      //   - Line Item contarcted amount is greater than (Media Plan Item impressions * 1.2)
      //    If yes, show double confirm
      if (
        this.order_line_item.contracted_amount
        > this.order_line_item.media_plan_item.impressions * 1.2
      ) {
        // double confirm
        return WebMessage.doubleConfirm(
          `The Booked amount exceeds the contracted amount (${numeral(
            this.order_line_item.media_plan_item.impressions,
          ).format('0,0')} vs ${numeral(this.order_line_item.contracted_amount).format('0,0')})`,
          'Booked amount exceeds',
          'Yes, The booked amount is correct.',
          { okTitle: 'Confirm' },
        ).then(result => {
          if (result) {
            callBack()
          }
        })
      }
      callBack()
    } else {
      callBack()
    }
    return false
  }

  public onSubmit(close: boolean = false) {
    this.checkBookedImpressions(() => {
      this.loading = true
      let start_at = moment(this.order_line_item.start_at)
      let end_at = moment(this.order_line_item.end_at)

      let potential_date_error = false

      if (start_at.isAfter(end_at)) {
        WebMessage.error('The start date cannot be after the end date.')
        this.loading = false
        return
      }

      if (
        this.order_line_item.remote_buffer_percentage > 0
        && this.order_line_item.buffer_rule !== 'percentage'
      ) {
        WebMessage.error(
          'You cannot set a remote buffer percentage without setting a buffer rule to percentage',
        )
        return
      }

      if (end_at.diff(start_at, 'days') > 90 && start_at.year() !== end_at.year()) {
        potential_date_error = true
      }

      if (potential_date_error) {
        WebMessage.doubleConfirm(
          `The start and end date are more than 90 days apart and cross over a year. Are you sure you want to continue?
        <br/><br/><strong>${start_at.format('MM/DD/YYYY')} - ${end_at.format(
  'MM/DD/YYYY',
)}</strong>`,
          'Flight Date Warning',
          'Yes, flight dates are correct.',
        ).then(result => {
          if (result) {
            this.saveLineCheck(close)
          } else {
            this.loading = false
          }
        })
      } else {
        this.saveLineCheck(close)
      }
    })
  }

  public checkForMissingConfig(clb: any) {
    // if
    //  geo is empty ||
    //  contains only US/national ||
    //  inventory is empty

    if (
      this.order_line_item.empty_geo
      || this.order_line_item.only_us_targeted
      || this.order_line_item.empty_inventory
    ) {
      WebMessage.doubleConfirm(
        `${this.order_line_item.before_save_issues_msg()}<p>Are you sure the settings are correct?</p>`,
        'Confirm settings',
        'Yes, all settings are correct.',
      ).then(result => {
        if (result) {
          clb(result)
        } else {
          this.loading = false
        }
      })
    } else {
      clb()
    }
  }

  public saveLineCheck(close: boolean = false) {
    this.loading = true

    if (!this.order_line_item.targetting.active) {
      this.order_line_item.targetting.time_restrictions = []
    }

    // this.save(close)

    if (this.target_media_plan_item.id) {
      const issues: any = []

      if (
        !this.target_media_plan_item.open_ended
        && this.target_media_plan_item.impressions > 0
        && this.order_line_item.contracted_amount > this.target_media_plan_item.impressions * 1.2
      ) {
        issues.push({
          message: `The line item has more impressions than the contracted amount of the media plan item (${this.target_media_plan_item.impressions} vs ${this.order_line_item.contracted_amount}).<br />`,
        })
      }

      if (issues.length > 0) {
        let msg = issues.reduce((acc, issue) => `${acc}* ${issue.message}`, '')
        this.hide_footer = true
        WebMessage.doubleConfirm(
          `${msg} Are you sure you want to continue?`,
          'Line Item issues',
          'Yes, I reviewed the errors above and it is correct.',
          { okTitle: 'Save' },
        ).then(result => {
          if (result) {
            this.save(close)
          } else {
            this.loading = false
            this.hide_footer = false
          }
        })
      } else {
        this.save(close)
      }
    } else {
      this.save(close)
    }
  }

  public addErrorsToInputs(index: number) {
    const s_date_vid = `specific-date-start-${index}`
    const e_date_vid = `specific-date-end-${index}`

    const s_time_vid = `specific-time-start-${index}`
    const e_time_vid = `specific-time-end-${index}`

    let msg: any = ['Overlapping time']
    let date_msg: any = ['Overlapping date']

    // @ts-ignore
    this.$refs.validator?.setErrors({
      [s_date_vid]: date_msg,
      [e_date_vid]: date_msg,
      [s_time_vid]: msg,
      [e_time_vid]: msg,
    })
  }

  public cancel() {
    let name: any = 'line-items'
    let params = {}
    if (this.$route.query.ref) {
      name = this.$route.query.ref
      if (name === 'order-overview' && this.order_line_item.order_id) {
        params = { id: this.order_line_item.order_id }
      }
    }
    this.$router.push({ name, params })
  }

  public async setDataFromOrder(order: any) {
    return Order.find(order.value).then((response: any) => {
      this.order = response
      this.order_line_item = Object.assign(this.order_line_item, {
        start_at: response.start_at
          ? moment(response.start_at).startOf('day').format('YYYY-MM-DD HH:mm:ss')
          : '',
        end_at: response.end_at
          ? moment(response.end_at).endOf('day').format('YYYY-MM-DD HH:mm:ss')
          : '',
        agency_id: response.agency_id || '',
        advertiser_id: response.advertiser_id || '',
      })

      this.order_line_item.order = this.order

      return response
    })
  }

  public async setDataFromMediaPlanItem() {
    // @ts-ignore
    return MediaPlanItem.find(this.$route.query.media_plan_item).then(
      (media_plan_item: MediaPlanItem) => {
        this.order_line_item.start_at = moment(media_plan_item.start_at)
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
        this.order_line_item.end_at = moment(media_plan_item.end_at)
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
        // @ts-ignore
        this.order_line_item.agency_id = media_plan_item.media_plan?.agency_id
        // @ts-ignore
        this.order_line_item.advertiser_id = media_plan_item.media_plan?.advertiser_id
        // @ts-ignore
        this.order_line_item.station_id = media_plan_item.media_plan?.station_id
        this.order_line_item.media_plan_id = media_plan_item.media_plan_id
        this.order_line_item.media_plan_item_id = media_plan_item.id

        this.target_media_plan_item = media_plan_item

        return media_plan_item
      },
    )
  }

  public setErrors(e: any) {
    // @ts-ignore
    this.$refs.validator?.setErrors(e)
  }

  public editCreative(creative: any) {
    this.create_new_creative = false
    this.loading_creative = true

    setTimeout(() => {
      this.create_new_creative = true // actually open the sidebar(edit/create)
      this.edit_creative = true
      let temp = JSON.parse(JSON.stringify(creative))

      setTimeout(() => {
        this.creative_form.editCreative(Creative.toObject(temp))
        this.loading_creative = false
      }, 500)
    }, 200)
  }

  public createNewCreative() {
    this.create_new_creative = false
    this.edit_creative = false
    setTimeout(() => {
      this.create_new_creative = true
      setTimeout(() => {
        this.creative_form.creative = new Creative()
        this.creative_form.creative.advertiser_id = this.order_line_item.advertiser_id
      }, 500)
    }, 200)
  }
}
