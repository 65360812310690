
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'
import RemoteTarget from '@/models/RemoteTarget'
import ViewModel from '@/models/ViewModel'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Widget from '../Widget/Widget.vue'
import DataTable from '../DataTable/index.vue'

@Component({
  components: {
    DataTable,
    Widget,
  },
})
export default class RemoteTargetTable extends ViewModel {
  @Ref() public dataTable!: DataTable

  @Prop({ required: true })
  @Prop()
  public extraQuery!: any // adds extra query to the query

  public query: string[] = []

  public loading: boolean = false

  public ready: boolean = false

  public records: number = 0

  public fields: any = [
    {
      key: 'id',
      label: 'Id',
      sortable: false,
      class: 'text-center align-middle',
      thClass: 'align-middle text-center',
      thStyle: { width: '25rem' },
      show: true,
    },
    {
      key: 'inventory_id',
      label: 'Adunit',
      sortable: false,
      class: 'text-center align-middle text-capitalize',
      thClass: 'align-middle text-center',
      show: true,
    },
    {
      // type badge
      key: 'remote_connection',
      label: 'Remote',
      sortable: false,
      class: 'text-center align-middle text-capitalize',
      thClass: 'align-middle text-center',
      show: true,
    },
    {
      // if null n/a else link
      key: 'remote_id',
      label: 'Remote Id',
      sortable: false,
      class: 'text-center align-middle text-capitalize text-capitalize',
      thClass: 'align-middle text-center',
      show: true,
    },
    {
      key: 'remote_status',
      label: 'Remote Status',
      sortable: false,
      class: 'text-center align-middle text-capitalize',
      thClass: 'align-middle text-center',
      show: true,
    },
  ]

  public fieldFilters: any = {}

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public async rows(ctx: any) {
    this.loading = true
    // @ts-ignore
    return RemoteTarget.paginate({
      page_size: ctx.perPage,
      page: ctx.currentPage,
      order_by: ctx.sortBy,
      order: ctx.sortDesc ? 'desc' : 'asc',
      query: [...ctx.filter],
    }).then((result: any) => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public mounted() {
    this.loadFilters()
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('remote-targets-filters').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }

      if (this.extraQuery && this.extraQuery.length) {
        if (!this.query) this.query = []
        this.query = this.query.concat(this.extraQuery)
      }

      setTimeout(() => {
        this.ready = true
      }, 200)
    })
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'remote-targets-filters',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'remote-targets-filters',
      data: null,
    })
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.dataTable.refresh()
  }
}
