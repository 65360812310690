
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import countires_list from '@/data/country_options'

@Component({
  components: {
    FormInput,
    IconAction,
  },
})
export default class CountryTargetting extends ViewModel {
  @Prop()
  value!: any

  @Prop()
  exclude!: any

  public selected_nodes: Array<any> = []

  public filter: string = ''

  public get list_of_countires() {
    return countires_list
      .filter((country: any) => {
        if (this.filter.length) {
          return (
            country.name.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase())
            || country.value.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase())
          )
        }
        return country
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  public get list_length() {
    return this.list_of_countires.length
  }

  public mounted() {
    if (!this.selected_nodes.length) {
      this.selected_nodes = [
        {
          ...countires_list.find(c => c.value === 'US'),
          action: 'include',
        },
      ]
    }
  }

  public isSelected(node: any) {
    return this.selected_nodes.some(sn => sn.value === node.value)
  }

  public select(node: any, include: boolean = true) {
    // console.log('selection', node)
    let index = this.selected_nodes.findIndex((s: any) => s.value === node.value)
    let _action = include ? 'include' : 'exclude'
    let obj = {
      ...node,
      action: _action,
    }

    // console.log('the index', index)

    if (index > -1) {
      Vue.set(this.selected_nodes[index], 'action', _action)
    } else {
      this.selected_nodes.push(obj)
    }

    this.emit()
  }

  public remove(index: number) {
    this.selected_nodes.splice(index, 1)
  }

  public removeAll() {
    this.selected_nodes = []
    this.emit()
  }

  public emit() {
    setTimeout(() => {
      let include = this.selected_nodes.filter(sn => sn.action === 'include')
      let exclude = this.selected_nodes.filter(sn => sn.action === 'exclude')
      // console.log('the exlude', exclude)
      const map_values = (arr: any) => arr.map((n: any) => n.value)

      this.$emit('input', map_values(include))
      this.$emit('update:exclude', map_values(exclude))
    }, 400)
  }
}
